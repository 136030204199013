<script setup lang="ts">
    const uspBarData = [
        { icon: 'stopwatch', title: 'icons/stopwatch', content: 'Easy assembly with the tools provided.' },
        { icon: 'headphones', title: 'icons/headphones-microphone', content: 'We help you throughout the process of buying your bike.' },
        { icon: 'awards', title: 'icons/awards', content: 'Praised by industry insiders, parents & riders.' },
        { icon: 'moneyback', title: 'icons/money-back-60', content: '30 day money-back guarantee' },
    ];
</script>

<template>
    <section class="usp-widget-container justify-center w-full max-w-full opacity-100 bg-gray-100">
        <div class="usp-row-container w-full relative md:flex font-mono">
            <template
                v-for="(item, index) in uspBarData"
                :key="item.id + 'copy'">
                <div class="usp-row flex flex-auto text-start w-full h-auto justify-center items-center">
                    <div class="usp-row-block inline-flex md:flex lg:flex items-center justify-center py-0 px-6 max-w-[380px]">
                        <atm-icon-usp-bar
                            :icon-name="item.icon"
                            :icon-title="item.title"
                            class="usp-icon ml-3"></atm-icon-usp-bar>

                        <div class="usp-row-content inline-block md:text-center lg:text-left ml-2 sm:gap-2 md:gap-0 text-gray-800 font-normal">
                            {{ item.content }} {{ uspBarData.slice(0, index).length > 0 }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<style>
    .usp-widget-container {
        padding-top: 32px !important;
        padding-bottom: 32px !important;
    }

    .usp-row:nth-child(2),
    .usp-row:nth-child(3),
    .usp-row:nth-child(4) {
        @apply md:hidden lg:flex;
    }
</style>
